import * as React from "react"
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";

export default function Artikel() {
    const breadcrumbs = [
        {name: 'Artikel', href: '/Artikel', current: true},
    ]

    return (
        <Layout>
            <Seo title="Meine Entwicklungsphilosophie"/>
            <BreadCrumbs pages={breadcrumbs}/>
            <div className="bg-white">

                {/* Artikel section */}
                <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
                    <div className="relative">
                        <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                            <h2 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                Artikel über meine Entwicklungstätigkeit
                            </h2>
                            <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                                Hier veröffentliche ich in unregelmäßigen Abständen Artikel zu meinen Erfahrungen in der
                                Software-Entwicklung.
                            </p>
                        </div>

                        {/* Artikel */}
                        <div
                            className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">

                            {/* Simple fails less */}
                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <Link to="/Artikel/SimpleFailsLess" className="block mt-2">
                                        <StaticImage
                                            src="../../images/SimpleFailsLess.jpg"
                                            formats={["auto", "webp", "avif"]}
                                            alt="Warum einfache Designs weniger oft fehlschlagen"
                                            className="h-48 w-full object-cover"
                                        />
                                    </Link>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-red-600">

                                            Einstellung

                                        </p>
                                        <Link to="/Artikel/SimpleFailsLess" className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">»Simple Fails Less«</p>
                                            <p className="mt-3 text-base text-gray-500">Diese 3 Worte sind die Essenz
                                                meiner Entwicklungserfahrung.
                                                In der Praxis sind einfache Lösungen komplizierten Lösungen in allen
                                                Belangen überlegen. Das fängt
                                                beim Design an und hört beim eigentlichen Programmcode auf.</p>
                                        </Link>
                                        <p className="mt-6"><Link to="/Artikel/SimpleFailsLess"
                                                                  className="text-blue-600 text-medium hover:underline">Mehr
                                            erfahren »</Link></p>
                                    </div>

                                </div>
                            </div>

                            {/* SOLID Principles */}
                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <Link to="/Artikel/SolidPrinciples" className="block mt-2">
                                        <StaticImage
                                            src="../../images/solid_principles.jpg"
                                            formats={["auto", "webp", "avif"]}
                                            alt="SOLID Prinzipien der objekt-orientierten Entwicklung"
                                            className="h-48 w-full object-cover"
                                        />
                                    </Link>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-red-600">
                                            Design
                                        </p>
                                        <Link to="/Artikel/SolidPrinciples" className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">SOLID Principles</p>
                                            <p className="mt-3 text-base text-gray-500">Programm-Design muss vor allem
                                                verständlich, flexibel und wartbar sein. Genau für diesen Zweck
                                                gibt es diese Prinzipien und jeder Programmierer tut gut daran, diese
                                                zu befolgen.</p>
                                        </Link>
                                        <p className="mt-6"><Link to="/Artikel/SolidPrinciples"
                                                                  className="text-blue-600 text-medium hover:underline">Mehr
                                            erfahren »</Link></p>
                                    </div>

                                </div>
                            </div>

                            {/* Test Driven Development (TDD) */}
                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <Link to="/Artikel/TestDrivenDevelopment">
                                        <StaticImage
                                            src="../../images/tdd.jpg"
                                            formats={["auto", "webp", "avif"]}
                                            alt="Test-driven Development"
                                            className="h-48 w-full object-cover"
                                        />
                                    </Link>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-red-600">
                                            Methodik
                                        </p>
                                        <Link to="/Artikel/TestDrivenDevelopment" className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">Test Driven Development
                                                (TDD)</p>
                                            <p className="mt-3 text-base text-gray-500">
                                                TDD halte ich für eine sinnvolle Entwicklungsmethodik. In dem Artikel
                                                führe ich aus, warum ich denke, dass jeder Entwickler testgetrieben
                                                entwickeln sollte.
                                            </p>
                                        </Link>
                                        <p className="mt-6"><Link to="/Artikel/TestDrivenDevelopment"
                                                                  className="text-blue-600 text-medium hover:underline">Mehr
                                            erfahren »</Link></p>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div>
        </Layout>
    )
}
